import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-loading-overlay',
  templateUrl: './ag-grid-header-checkbox.component.html',
  styleUrls: ['./ag-grid-header-checkbox.component.scss']
})
export class AgGridHeaderCheckboxComponent {

  @ViewChild('checkbox', {static: false}) public checkbox;

  public params: any;
  public title: string;

  constructor() { }

  agInit(params): void {
    this.params = params;
  }

  onClick( obj ) {
    let checkbox = obj.checkbox;
    let children = checkbox.nativeElement.children;
    let checked = children[0].classList;
    let unchecked = children[1].classList;
    let checkboxChecked = false;

    checked.forEach( val => {
      if( val == 'ag-hidden' ) {
        checkboxChecked = true;
      }
    });

    // header checkbox
    if( checkboxChecked ) {
      checked.remove('ag-hidden');
      unchecked.add( 'ag-hidden' );
    } else {
      checked.add('ag-hidden');
      unchecked.remove( 'ag-hidden' );
    }

    // 모든 노드 값 변경
    this.params.api.forEachNode( function(rowNode, index) {
      let tmp = {};

      if( checkboxChecked ) {
        rowNode.setDataValue( this.params.field, true );
      } else {
        rowNode.setDataValue( this.params.field, false );
      }

    }.bind(this));
  }
}
